/* 
  Edit studies component
  Will be implemented soon, to assist in refining difficulty levels. 
*/


import React, { Component } from 'react';

export default class EditStudies extends Component {
  render() {
    return (
      <div>
         <p>Welcome to Edit Studies Component!!</p>
      </div>
    )
  }
} 